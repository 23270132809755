import { useCallback, useEffect, useState } from 'react';
import * as icons from 'images/icons/attachments';
import { useGeneratedPresignedUrlByAttachmentId } from 'remote-state/attachments';

import { StyledAttachmentChipWrapper } from './StyledAttachmentChip';
import Thumbnail from '../../../common/components/thumbnail';
import { fileIcon } from './attachmentIcons';
import { InactiveAttachmentChip } from './InactiveAttachmentChip';
import { useAttachmentAvailability } from '../../../remote-state/useAttachmentsServiceHooks';

export const AttachmentChip = (props) => {
  const {
    attachmentId,
    fileId,
    subResourceType,
    extension,
    originalName,
    isDeleted,
    isReadOnly,
    isDownloaded,
    onAttachmentDeleted,
    deleteAttachmentEnabled,
    onDisplayPreview,
    filesData = [],
  } = props;
  const icon = fileIcon(extension);
  const {
    data: { downloadUrl: url },
  } = useGeneratedPresignedUrlByAttachmentId(attachmentId, fileId, subResourceType);
  const [isAttachmentAvailable, setIsAttachmentAvailable] = useState(false);
  const isImage = icon === 'image';
  const showDeleteBtn = deleteAttachmentEnabled && (!isReadOnly || (isReadOnly && !isDeleted && !isDownloaded));
  const {
    data: attachmentAvailability,
    updateAttachmentAvailability,
    isFetched,
    isSuccess,
  } = useAttachmentAvailability(url);

  const index = filesData.findIndex(file => file.attachmentId===attachmentId)

  useEffect(() => {
    if (isSuccess && isFetched) {
      setIsAttachmentAvailable(!!attachmentAvailability);
    }
  }, [isSuccess, isFetched, attachmentAvailability]);

  const onDelete = useCallback(
    (attachmentId) => {
      onAttachmentDeleted(attachmentId);
      setIsAttachmentAvailable(false);
      updateAttachmentAvailability(false);
    },
    [onAttachmentDeleted, updateAttachmentAvailability],
  );


  return (isFetched && isSuccess) && (isDeleted) ? (
    <InactiveAttachmentChip
      icon={icons[icon]}
      filename={originalName}
      isDeleted={isDeleted}
      attachmentId={attachmentId}
    />
  ) : (
    <StyledAttachmentChipWrapper
      data-cy={`attachment-uploader-chip-${attachmentId}`}
      data-testid={`attachment-uploader-chip-${attachmentId}`}
      disabled={isDeleted || !isAttachmentAvailable}
      showTitle={!isImage}
      showDownloadBtn={deleteAttachmentEnabled}
      showDeleteBtn={showDeleteBtn}
    >
      <Thumbnail
        attachmentId={attachmentId}
        index={index}
        extendedFileName={originalName}
        extension={extension}
        fileId={attachmentId}
        isDefaultImage={isImage && !isAttachmentAvailable}
        onAttachmentDeleted={onDelete}
        onDisplayPreview={onDisplayPreview}
      />
    </StyledAttachmentChipWrapper>
  );
};
