import { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import FilesPreview from 'common/components/thumbnail/FilesPreview';
import { baseURL } from 'services/axiosBaseInstance';
import { AttachmentPath, SubResourceType } from 'services/attachments';
import { useScreenCaptures } from 'remote-state/useScreenCapturesHooks';
import { useTicketAttachments } from 'remote-state/attachments';
import { StyledAttachments, StyledEditorAttachments } from './style';
import { MoreAttachmentsWrapper } from './MoreAttachmentsWrapper';

export const Attachments = (props) => {
  const {
    attachments,
    isReadOnly,
    isDeleted,
    isDownloaded,
    shouldTriggerDeleteApi,
    onAttachmentDeleted,
    deleteAttachmentEnabled,
    srPanelCollapsed,
  } = props;
  const [selectedFileIndex, setSelectedImageIndex] = useState(0);
  const [displayPreview, setDisplayPreview] = useState(false);
  const { screenCaptures = [] } = useScreenCaptures();
  const {srAttachments: { data: ticketAttachments = [] },} = useTicketAttachments();

  const wrapperRef = useRef(null);

  const handleDisplayPreview = (index) => {
    setSelectedImageIndex(index);
    setDisplayPreview(true);
  };

  const handleImageRemove = () => {
    setDisplayPreview(false);
  };

  const filesData = [];
  for (const attachment of ticketAttachments) {
    const {
      attachment: {
        metadata: {
          id: attachmentId,
          originalName,
          extension,
          userName: username,
          createdDate,
          fileId,
          subResourceType,
        },
      },
      count,
    } = attachment;
    const fileUrl = `${baseURL}${AttachmentPath.V3}${attachmentId}/payload`;
    const fileData = {
      key: attachment.Id,
      attachmentId,
      fileId,
      subResourceType,
      src: fileUrl,
      fileName: originalName,
      extension,
      userName: username,
      createdDate,
      removeParams: { fileUrl },
      attachmentUsageCount: count,
    };
    if (subResourceType !== SubResourceType.ACTION_ITEM) {
      filesData.push(fileData);
    }
  }

  for (const screenCapture of screenCaptures) {
    filesData.push({
      ...screenCapture,
      src: screenCapture.fileUrl,
      key: screenCapture.fileName,
      attachmentId: screenCapture.fileName,
      screenCaptureType: screenCapture.type,
      isScreenCapture: true,
    });
  }

  return !isReadOnly ? (
    <StyledEditorAttachments>
      <MoreAttachmentsWrapper
        attachments={attachments}
        wrapperRef={wrapperRef}
        isReadOnly={isReadOnly}
        isDeleted={isDeleted}
        isDownloaded={isDownloaded}
        shouldTriggerDeleteApi={shouldTriggerDeleteApi}
        onAttachmentDeleted={onAttachmentDeleted}
        deleteAttachmentEnabled={deleteAttachmentEnabled}
        noButton="true"
        srPanelCollapsed={srPanelCollapsed}
        handleDisplayPreview={handleDisplayPreview}
      />
      {displayPreview && (
        <FilesPreview
          filesData={filesData}
          selectedFileIndex={selectedFileIndex}
          onClose={() => setDisplayPreview(false)}
          onFileRemove={handleImageRemove}
        />
      )}

    </StyledEditorAttachments>
  ) : (
    <StyledAttachments ref={wrapperRef}>
      <MoreAttachmentsWrapper
        isReadOnly={isReadOnly}
        wrapperRef={wrapperRef}
        attachments={attachments}
        isDeleted={isDeleted}
        isDownloaded={isDownloaded}
        shouldTriggerDeleteApi={shouldTriggerDeleteApi}
        onAttachmentDeleted={onAttachmentDeleted}
        deleteAttachmentEnabled={deleteAttachmentEnabled}
        srPanelCollapsed={srPanelCollapsed}
        handleDisplayPreview={handleDisplayPreview}
        filesData={filesData}
      />
      {displayPreview && (
        <FilesPreview
          filesData={filesData}
          selectedFileIndex={selectedFileIndex}
          onClose={() => setDisplayPreview(false)}
          onFileRemove={handleImageRemove}
        />
      )}
    </StyledAttachments>
  );
};

Attachments.propTypes = {
  attachments: PropTypes.array,
  isReadOnly: PropTypes.bool,
  isDeleted: PropTypes.bool,
  isDownloaded: PropTypes.bool,
  onAttachmentDeleted: PropTypes.func,
};

Attachments.defaultProps = {
  attachments: [],
  onAttachmentDeleted: () => {},
  isReadOnly: true,
  isDeleted: false,
  isDownloaded: false,
};
