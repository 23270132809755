import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from '@mui/material';
import useDelayHover from 'common/utils/hooks/useDelayHover';
import { DropdownSelect } from 'common/components/dropdownSelect';
import CircularProgress from 'common/components/circularProgress';
import Tooltip from 'common/components/tooltip';
import { getCategoryListAttributes } from 'services/ticketService';
import { useDispatch, useSelector } from 'react-redux';
import { QUERIES_KEYS } from 'constant';
import { useApplicationData } from 'remote-state/applicationHooks';
import { PENDO_TRACK_EVENTS } from 'constants/index';
import useHiddenSelectOption from 'common/utils/hooks/useHiddenSelectOption';
import usePendoTrackEvents from 'common/utils/hooks/usePendoTrackEvents';
import { SECOND_LEVEL_CATEGORY_FIELD_ID, THIRD_LEVEL_CATEGORY_FIELD_ID } from 'features/header/categories/constants';
import {
  addPrimaryCategory,
  addSecondaryCategory,
  addThirdLevelCategory,
  EMPTY_CATEGORY,
  selectIsOpened,
  selectPrimaryCategory,
  selectSecondaryCategory,
  selectThirdLevelCategory,
  setIsOpened,
  updateEmptyCategory,
} from 'store/categorySlice';
import {
  modifyCategoriesFilter,
  useColumnFilterList,
  useModifiedCategoriesAttributes,
  useUpdateSR,
} from 'remote-state/ticketServiceHooks';
import { ReactComponent as GreyAiStars } from 'images/icons/generativeAi/grey_stars.svg';
import { useSuggestedCategoryDropdownControl } from 'features/suggestedCategory/suggestedCategoryHooks';
import { VirtualizedScrollContext } from 'common/components/dropdownSelect/VirtualizedScroll/VirtualizedScrollContext';
import { categoriesFieldNames } from 'features/queue/utils';
import useValidateSrLockBeforeUpdate from '../../hooks/useValidateSrLockBeforeUpdate';
import { StyledItem } from '../SelectRender/SelectRender.styles';
import useTexts from './useTexts';

export const CategoryRender = memo((props) => {
  const {
    value,
    srId,
    srType,
    fieldId,
    rowData,
    delay = 0,
    headerName,
    required,
    hoverEffect,
    listKey,
    delayedHover,
    className = '',
    disabled = false,
    categoryCaption,
  } = props;

  const theme = useTheme();
  const dispatch = useDispatch();

  const { checkAndUpdateSr } = useUpdateSR();
  const { isError, isInitialLoading } = useColumnFilterList(listKey);
  const hoverRef = useRef();
  useDelayHover(hoverRef, delayedHover, hoverEffect);
  const { data: isTripleCategory } = useApplicationData(QUERIES_KEYS.IS_EXTERNAL_MODE);
  const {
    selectCategoryText,
    selectSubCategoryText,
    select3rdLevelCategoryText,
    categoryNoValue,
    mandatoryFieldError,
  } = useTexts();

  const [selectedItem, setSelectedItem] = useState({});
  const [isAfterInitialLoad, setIsAfterInitialLoad] = useState(false);
  const [catId, setCatId] = useState(null);

  const isOpened = useSelector(selectIsOpened());
  const primaryCategory = useSelector(selectPrimaryCategory(srId?.toString()));
  const secondaryCategory = useSelector(selectSecondaryCategory(srId?.toString()));
  const thirdLevelCategory = useSelector(selectThirdLevelCategory(srId?.toString()));

  const selectText = useMemo(() => {
    let text = selectCategoryText;

    if (listKey === 'secondaryCategory') {
      text = selectSubCategoryText;
    } else if (listKey === 'thirdLevelCategory') {
      text = select3rdLevelCategoryText;
    }
    return text;
  }, [listKey, selectCategoryText, selectSubCategoryText, select3rdLevelCategoryText]);

  const styleClass = useMemo(() => {;
    if ((selectedItem?.name || categoryCaption) && selectedItem?.placeholder !== selectText) return 'categoryItem';
    return `valueClass_${selectedItem.valueClass}`;
  }, [selectedItem, categoryCaption, selectText]);

  const labelRef = useRef(null);
  const noValueText = categoryNoValue;
  const isEmptyRequiredValue = required && !Object.keys(selectedItem).length;

  const stylingVariant = 'mainCategory';
  const [anchor, setAnchor] = useState(null);
  const [isClearCategoriesPerformed, setIsClearCategoriesPerformed] = useState(false);
  const isDefault = !['Priority', 'Status'].includes(headerName);
  const categoryList = useModifiedCategoriesAttributes(
    listKey,
    primaryCategory?.value,
    secondaryCategory?.value,
    srType,
  );
  const pendoTrackEvents = usePendoTrackEvents();
  const queryKeys = useMemo(() => {
    switch (fieldId) {
      case SECOND_LEVEL_CATEGORY_FIELD_ID:
        return [primaryCategory?.value];
      case THIRD_LEVEL_CATEGORY_FIELD_ID:
        return [primaryCategory?.value, secondaryCategory?.value];
      default:
        return null;
    }
  }, [fieldId, primaryCategory, secondaryCategory]);

  const hiddenSelectOptionsInput = useMemo(
    () => ({
      list: categoryList,
      fieldId,
      value: selectedItem?.id,
      srType,
      queryKeys,
    }),
    [categoryList, selectedItem?.id, fieldId, srType, queryKeys],
  );

  useHiddenSelectOption(hiddenSelectOptionsInput);

  const handleSaveSR = useCallback(
    async (categories) => {
      const { primaryCategoryId = -1, secondaryCategoryId = -1, thirdLevelCategoryId = -1 } = categories;

      const requestParams = { category: {} };

      requestParams.category.primaryCategory = String(primaryCategoryId);
      requestParams.category.secondaryCategory = String(secondaryCategoryId);
      if (isTripleCategory) requestParams.category.thirdLevelCategory = String(thirdLevelCategoryId);
      const object = { category: JSON.stringify(requestParams.category) };
      await checkAndUpdateSr({
        requestParams: object,
        id: srId.toString(),
        queueUpdate: true,
      });
    },
    [checkAndUpdateSr, srId, isTripleCategory],
  );

  const { handleUpdateSR } = useValidateSrLockBeforeUpdate({ srId, handleSaveSR });

  const anchorTimeout = useRef();
  const handleClose = useCallback(() => {
    setAnchor(null);
  }, []);

  useEffect(() => () => clearTimeout(anchorTimeout.current), []);

  useEffect(() => {
    if (listKey === 'primaryCategory' && !primaryCategory && rowData) {
      dispatch(addPrimaryCategory({ id: srId?.toString(), value }));
    } else if (listKey === 'secondaryCategory' && !secondaryCategory && rowData) {
      dispatch(addSecondaryCategory({ id: srId?.toString(), value }));
    } else if (listKey === 'thirdLevelCategory' && !thirdLevelCategory && rowData) {
      dispatch(addThirdLevelCategory({ id: srId?.toString(), value }));
    }
  }, [dispatch, srId, value, listKey, rowData, primaryCategory, secondaryCategory, thirdLevelCategory]);

  useEffect(() => {
    if (primaryCategory?.value && !isAfterInitialLoad && listKey === 'primaryCategory') {
      setCatId(primaryCategory?.value);
      setIsAfterInitialLoad(true);
    }
  }, [listKey, isAfterInitialLoad, primaryCategory?.value]);

  useEffect(() => {
    if (secondaryCategory?.value && !isAfterInitialLoad && listKey === 'secondaryCategory') {
      setCatId(secondaryCategory?.value);
      setIsAfterInitialLoad(true);
    }
  }, [listKey, isAfterInitialLoad, secondaryCategory?.value]);

  useEffect(() => {
    if (thirdLevelCategory?.value && !isAfterInitialLoad && listKey === 'thirdLevelCategory') {
      setCatId(thirdLevelCategory?.value);
      setIsAfterInitialLoad(true);
    }
  }, [listKey, isAfterInitialLoad, thirdLevelCategory?.value]);

  useEffect(() => {
    const isCategoryCaptionEmpty = rowData?.categoryCaption === ' ' || rowData?.categoryCaption === '';
    if (isAfterInitialLoad && ((isCategoryCaptionEmpty && rowData?.value !== 0) || rowData?.categoryCaption)) {
      const name = isCategoryCaptionEmpty ? noValueText : rowData?.categoryCaption;
      setSelectedItem({ name, id: rowData?.value });
    }
  }, [catId, listKey, isAfterInitialLoad, rowData, noValueText]);

  useEffect(() => {
    if (listKey !== 'secondaryCategory') {
      return;
    }
    if (secondaryCategory?.value === -1) {
      if (!isClearCategoriesPerformed) {
        dispatch(updateEmptyCategory({ category: 'secondaryCategory', id: srId.toString() }));
      }
      setSelectedItem({ placeholder: selectText });
    } else if (secondaryCategory?.value === 0 || secondaryCategory?.value === 124) {
      setSelectedItem({ name: noValueText, id: secondaryCategory?.value });
    }
  }, [listKey, selectText, noValueText, secondaryCategory, dispatch, srId, isClearCategoriesPerformed]);

  useEffect(() => {
    if (listKey !== 'thirdLevelCategory') {
      return;
    }
    if (thirdLevelCategory?.value === -1) {
      if (!isClearCategoriesPerformed) {
        dispatch(updateEmptyCategory({ category: 'thirdLevelCategory', id: srId.toString() }));
      }
      setSelectedItem({ placeholder: selectText });
    } else if (thirdLevelCategory?.value === 0 || thirdLevelCategory?.value === 124) {
      setSelectedItem({ name: noValueText, id: thirdLevelCategory?.value });
    }
  }, [listKey, selectText, noValueText, thirdLevelCategory, dispatch, srId, isClearCategoriesPerformed]);

  const handleChange = useCallback(
    (value) => {
      setSelectedItem(value);
      const identifier = value.id;

      if (listKey === 'primaryCategory') {
        dispatch(setIsOpened({ id: srId, listKey: 'secondaryCategory' }));
        dispatch(addPrimaryCategory({ id: srId.toString(), value: Number(identifier) }));
      } else if (listKey === 'secondaryCategory') {
        dispatch(addSecondaryCategory({ id: srId.toString(), value: Number(identifier) }));

        if (!isTripleCategory) {
          dispatch(setIsOpened(null));
          handleUpdateSR({ primaryCategoryId: primaryCategory?.value, secondaryCategoryId: Number(identifier) });
        } else {
          dispatch(setIsOpened({ id: srId, listKey: 'thirdLevelCategory' }));
        }
      } else if (listKey === 'thirdLevelCategory') {
        dispatch(setIsOpened(null));
        dispatch(addThirdLevelCategory({ id: srId.toString(), value: Number(identifier) }));
        handleUpdateSR({
          primaryCategoryId: primaryCategory?.value,
          secondaryCategoryId: secondaryCategory?.value,
          thirdLevelCategoryId: Number(identifier),
        });
      }

      handleClose();
    },
    [
      dispatch,
      handleClose,
      handleUpdateSR,
      srId,
      listKey,
      isTripleCategory,
      primaryCategory?.value,
      secondaryCategory?.value,
    ],
  );
  useEffect(() => {
    if (categoryList?.length === 1 && anchor) {
      handleChange(categoryList[0]);
    }
  }, [categoryList, anchor, handleChange]);

  const {
    virtualizedScrollContextValue,
    selectOptionsWrapperClassName,
    dropdownHeightExtension,
    indexOffset,
    isSuggestedCategoryShown,
  } = useSuggestedCategoryDropdownControl({
    srId,
    currentCategory: {
      primaryId: primaryCategory?.value,
      secondaryId: secondaryCategory?.value,
      thirdLevelId: thirdLevelCategory?.value,
    },
    selectSuggestedCategoryProps: {
      srId,
      queueHandlerOptions: {
        handleClose,
        handleUpdateSR,
        listKey,
        setSelectedItem,
      },
    },
  });

  const dropdownStyles = useMemo(
    () => ({
      backgroundColor: theme.palette.blueLabels.bt_cyan_reg,
      color: theme.palette.background.autocompleteSecondary,
      fontFamily: 'Roboto-Regular',
      borderRadius: '16px',
    }),
    [theme],
  );

  const options = useMemo(
    () => categoryList?.map((category) => ({ ...category, customStyle: dropdownStyles })),
    [categoryList, dropdownStyles],
  );

  const selection = useMemo(
    () => (selectedItem?.id || selectedItem?.id === 0 ? [selectedItem.id] : []),
    [selectedItem?.id],
  );

  const backendQueryConfig = useMemo(
    () => ({
      fetchingPromise: async (query) => {
        const searchResp = await getCategoryListAttributes(
          listKey === 'primaryCategory' ? null : primaryCategory?.value,
          ['primaryCategory', 'secondaryCategory'].includes(listKey) ? null : secondaryCategory?.value,
          query,
          srType,
          fieldId,
        );
        return modifyCategoriesFilter(searchResp?.values, ['thirdLvlCategory']);
      },
    }),
    [primaryCategory?.value, secondaryCategory?.value, listKey, srType, fieldId],
  );

  const onClickAway = useCallback(() => dispatch(setIsOpened(null)), [dispatch]);

  const clearValueHandler = useCallback(() => {
    if (listKey === 'primaryCategory') {
      dispatch(addPrimaryCategory({ id: srId.toString(), value: EMPTY_CATEGORY }));
      setSelectedItem({ placeholder: selectCategoryText });
      handleUpdateSR({});
    } else if (listKey === 'secondaryCategory') {
      dispatch(addSecondaryCategory({ id: srId.toString(), value: EMPTY_CATEGORY }));
      setSelectedItem({ placeholder: selectCategoryText });
      setIsClearCategoriesPerformed(true);
    } else if (listKey === 'thirdLevelCategory') {
      dispatch(addThirdLevelCategory({ id: srId.toString(), value: EMPTY_CATEGORY }));
      setSelectedItem({ placeholder: selectCategoryText });
      setIsClearCategoriesPerformed(true);
    }
  }, [handleUpdateSR, listKey, selectCategoryText, srId, dispatch]);

  const isAiIndicationShown = isSuggestedCategoryShown && listKey === 'primaryCategory';
  const handleOpen = useCallback(() => {
    if (!disabled) {
      anchorTimeout.current = setTimeout(() => {
        setAnchor(labelRef.current);
      }, delay);
      if (isAiIndicationShown) pendoTrackEvents(PENDO_TRACK_EVENTS.SUGGESTED_CATEGORY_CHECKED, { srId });
    }
  }, [disabled, delay, isAiIndicationShown, pendoTrackEvents, srId]);

  useEffect(() => {
    if (isOpened?.id === srId && isOpened?.listKey === listKey) {
      handleOpen();
    }
  }, [listKey, srId, isOpened, labelRef, handleOpen]);

  if (isInitialLoading) return <CircularProgress />;
  if (isError) return <div>{isError}</div>;

  return (
    <StyledItem ref={hoverRef} isDefault={isDefault} disabled={disabled} required={required}>
      <Tooltip
        isError
        title={mandatoryFieldError}
        placement="top"
        disableHoverListener={!isEmptyRequiredValue}
        text={
          <span
            data-cy={categoriesFieldNames[fieldId] || 'select-category'}
            ref={labelRef}
            className={`${categoriesFieldNames[fieldId]}Item ${className}`}
            onClick={handleOpen}
            aria-hidden="true"
          >
            <span key={selectedItem?.id} className={`chip ${styleClass}`}>
              <span className={`text ${isAiIndicationShown ? 'ai' : ''}`}>
                <Tooltip
                  isTruncatedText
                  text={selectedItem?.name}
                  placement="top-start"
                  disableHoverListener={isEmptyRequiredValue}
                  placeholder={disabled ? '--' : selectedItem?.placeholder || categoryCaption || selectText}
                />
                {isAiIndicationShown && <GreyAiStars style={{ marginLeft: '0.375rem' }} />}
              </span>
            </span>
          </span>
        }
      />
      <VirtualizedScrollContext.Provider value={virtualizedScrollContextValue}>
        <DropdownSelect
          options={options}
          handleChange={handleChange}
          handleClose={handleClose}
          onClickAway={onClickAway}
          stylingVariant={stylingVariant}
          selection={selection}
          anchor={anchor}
          backendQueryConfig={backendQueryConfig}
          handleOpen={handleOpen}
          isCategory
          additionalWrapperClassName={selectOptionsWrapperClassName}
          indexOffset={indexOffset}
          dropdownHeightExtension={dropdownHeightExtension}
          handleClearValue={clearValueHandler}
          sortBy="name"
          keyString="id"
          captionString="name"
        />
      </VirtualizedScrollContext.Provider>
    </StyledItem>
  );
});
