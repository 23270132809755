import { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import { renderToString } from 'react-dom/server';
import { debounce } from 'lodash-es';
import FroalaEditor from 'react-froala-wysiwyg';
import Tribute from 'tributejs';
import useClickAway from 'common/utils/hooks/useClickAway';
import usePreviousValue from 'common/utils/hooks/usePreviousValue';
import AttachmentDropzone from 'common/components/attachmentDropzone';
import { getUsersWithParamsFilterNotesPermission } from 'services/userService';
import { getGroupsWithParams } from 'services/groups';
import { ReactComponent as IconMinimize } from 'images/icons/minimize.svg';
import { ReactComponent as IconMaximize } from 'images/icons/maximize.svg';
import { editorActionData } from 'features/resolutionPanel/middlePanel/auditLog/constants';
import { editorActionTypes } from 'features/resolutionPanel/middlePanel/auditLog/constants/editorActionTypes';
import { useApplicationData } from 'remote-state/applicationHooks';
import { useGetAIConfigData } from 'remote-state/aiConfigDataHook';
import { QUERIES_KEYS } from 'constant';
import { UploadFromExistingAttachments } from '../../../uploadFromExistingAttachments/uploadFromExistingAttachments';
import MessageHeader from '../headers/message';
import { DEFAULT_CONFIG, RICH_TEXT_EDITOR_TYPES } from '../constants';
import { useCustomPlugins, useCustomButtons } from '../hooks';
import RichTextDropdown from '../components/richTextDropdown';
import '../customIcons';
import { RephraseToolbar } from '../components/rephraseInlineToolbar';
import { useRephraseToolbar } from '../components/rephraseInlineToolbar/hooks';

const RichTextEditor = (props, ref) => {
  const {
    fieldName,
    showToolbar,
    disabled,
    editorText,
    placeholder,
    CustomFooter,
    toolbarButtons = [],
    isAttachmentsEnabled,
    editorWrapRef,
    editorRef,
    onBlur,
    onChange,
    handleClickAway,
    handleExpanded,
    handlePasteAttachment,
    isExpanded,
    isFullSize,
    hasFullSize,
    toggleFullSize,
    isUploadFileProgress,
    onAttachmentDrop,
    onChangeUploadImageProgress,
    onChangeUploadFileProgress,
    onChangeImagePreviewsConfig,
    isEditorDirty,
    editorType,
    setEditorId,
    setIsUploadFromMenuOpened,
    setUploadFromMenuId,
    isUploadFormAttachmentsMenuOpened,
    setIsUploadFormAttachmentsMenuOpened,
    onFocus,
    isFocusedByDefault = true,
    charCounterMax,
    sourceId,
    type,
  } = props;
  const editorElementClassName = editorActionData[editorType]?.editorElementClassName || 'editor-element';
  const previousContent = usePreviousValue(editorText);

  const { data: isAiEnabled } = useApplicationData(QUERIES_KEYS.IS_AI_ENABLED);
  const { data: isAiAuthor } = useGetAIConfigData(QUERIES_KEYS.IS_AI_AUTHOR);

  const [shouldBlur, setShouldBlur] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const editorContainerRef = useRef(null);

  const getMentionUsers = debounce(async (text = '', cb) => {
    const mentions = [];
    const users = await getUsersWithParamsFilterNotesPermission({
      isAdmin: 'Y',
      calculatedUserName: text,
      sort: 'uiSort',
      resultPageNumber: 0,
    });
    if (users.searchResults.length > 0) mentions.push(...users.searchResults);
    const groups = await getGroupsWithParams({
      groupType: 1,
      groupName: text,
      resultPageNumber: 0,
    });
    if (groups.searchResults.length > 0) mentions.push(...groups.searchResults);
    cb(mentions);
  }, 200);

  const tribute = new Tribute({
    trigger: '@',
    selectTemplate: (item) =>
      renderToString(
        <a
          href="#"
          // data-mentions is used to searching users mentions in core. please don't change its name and if you do change then please change in core as well
          className="mention-text"
          data-mention={JSON.stringify({
            id: item.original.id,
            userName: item.original.userName,
            groupName: item.original.groupName,
          })}
        >
          @{item.original.calculatedUserName || item.original.groupName}
        </a>,
      ),
    menuItemTemplate: (result) => result.original.calculatedUserName || result.original.groupName,
    lookup: (data) => data.calculatedUserName || data.groupName,
    searchOpts: { skip: true },
    fillAttr: 'calculatedUserName',
    async values(text, cb) {
      await getMentionUsers(text, (users) => cb(users));
    },
    allowSpaces: true,
  });

  const toggleDropdown = () => {
    setOpenDropdown((previousState) => !previousState);
  };

  useCustomPlugins();
  useCustomButtons({
    refs: { tribute },
    isActive: isActive || isExpanded,
    callbacks: {
      onChangeImagePreviewsConfig,
      toggleDropdown,
      setUploadFromMenuId,
      setIsUploadFromMenuOpened,
      onChangeUploadImageProgress,
      onChangeUploadFileProgress,
    },
  });

  useClickAway(
    editorWrapRef,
    () => {
      handleClickAway();
      setIsActive(false);
      if (shouldBlur && !isUploadFormAttachmentsMenuOpened) {
        const editorData = editorRef.current?.html.get();
        onBlur(editorData);
        if (!isEditorDirty && !isUploadFileProgress) {
          handleExpanded(false);
        }
        setShouldBlur(false);
      }
    },
    'mouseup',
  );

  useImperativeHandle(ref, () => ({
    focus: () => {
      editorRef.current?.events?.focus();
    },
  }));

  const handleCharacterLimit = (charCount) => {
    const charCapacity = `${charCount}/${charCounterMax}`;
    const counterElement = editorRef.current.$box?.find('.fr-counter-element')[0];
    if (counterElement) {
      counterElement.innerHTML = charCapacity;
    }
  };

  const handleCreateLimitCharactersElement = (charCount) => {
    const element = document.createElement('span');
    element.className = 'fr-counter-element';
    editorRef.current.$tb?.[0].appendChild(element);
    handleCharacterLimit(charCount);
  };

  const onInit = (editor) => {
    if (editor?.id && setEditorId) {
      setEditorId(editor?.id);
    }
    editorRef.current = editor;
    tribute.attach(editor.el);
    const charCount = editor.charCounter.count();
    handleCreateLimitCharactersElement(charCount);

    // when clicking on element with className 'editor-element' -> don't close the editor
    editor.events.bindClick(editor.$('body'), editorElementClassName, () => {
      editor.events.focus();
    });
    editor.events.on('click', () => {
      setIsActive(true);
    });
    editor.events.on('image.beforePasteUpload', handlePasteAttachment);
    editor.events.on('image.beforeUpload', () => false);
    editor.events.on('image.error', () => false);

    if (isExpanded && isFocusedByDefault) {
      editor.events.focus();
    }
    if (!showToolbar) {
      editor.hide();
    }
    if (disabled) {
      editor.edit.off();
    }
  };

  const handleCollapsibleTimeout = useRef();

  const handleCollapsible = () => {
    handleCollapsibleTimeout.current = setTimeout(() => {
      const collapsibles = document.querySelectorAll('.toggle-content-display');

      function expand(el) {
        el.style.maxHeight = `${el.scrollHeight + 5}px`;
        el.style.height = `${el.scrollHeight + 5}px`;
        el.classList.add('expanded');
      }

      function collapse(el) {
        el.style.maxHeight = null;
        el.classList.remove('expanded');
      }

      collapsibles.forEach((collapsible) => {
        const collapsibleBody = collapsible.nextElementSibling;

        if (collapsibleBody.classList.contains('expanded')) {
          collapse(collapsibleBody);
        }

        collapsible.addEventListener('click', () => {
          if (collapsibleBody.style.maxHeight) {
            collapse(collapsibleBody);
          } else {
            expand(collapsibleBody);
          }
        });
      });
    }, 1000);
  };

  useEffect(() => () => clearTimeout(handleCollapsibleTimeout.current), []);

  const handleCharCount = (editor, operation) => {
    let charCount = editor.charCounter.count();
    switch (operation) {
      case 'increase':
        charCount += 1;
        break;
      case 'decrease':
        charCount -= 1;
        break;
      default:
        return charCount;
    }
    return charCount > -1 ? charCount : 0;
  };

  const handleChange = (value) => {
    if (!value) {
      handleCharacterLimit(0);
    }
    if (previousContent && previousContent !== value) {
      setShouldBlur(true);
    }
    if (onChange) {
      onChange(value);
    }
  };

  const handleFocus = () => {
    onChangeUploadImageProgress?.(false);
    onChangeUploadFileProgress?.(false);
    if (onFocus) {
      onFocus();
    }
    setShouldBlur(true);
    if (!isExpanded) {
      editorRef.current?.events?.focus();
      handleExpanded(true);
    }
  };

  const handleKeypress = (editor) => {
    const charCount = handleCharCount(editor, 'increase');
    handleCharacterLimit(charCount);
  };

  const handleKeydown = (editor) => {
    const charCount = handleCharCount(editor, 'decrease');
    handleCharacterLimit(charCount);
  };

  const handleKeyup = (editor) => {
    const charCount = handleCharCount(editor);
    handleCharacterLimit(charCount);
  };

  const renderHeader = () => {
    switch (editorType) {
      case editorActionTypes.MESSAGE:
        return <MessageHeader />;
      default:
        return null;
    }
  };

  const { toolbarProps: rephraseInlineToolbarProps } = useRephraseToolbar({
    fieldName,
    editorRef,
    updateCharCount: () => {
      handleCharacterLimit(handleCharCount(editorRef.current));
    },
    isTemplatePage: type === RICH_TEXT_EDITOR_TYPES.TEMPLATE,
    srId: sourceId,
  });

  const showInlineToolbar = !disabled && isAiEnabled && (isActive || isExpanded) && isAiAuthor;

  return (
    <>
      <div
        id={`scrollable-container-${editorRef?.current?.id}`}
        className="richtext-wrap"
        role="presentation"
        onKeyUp={handleFocus}
        onClick={handleFocus}
        ref={editorContainerRef}
      >
        {showInlineToolbar && <RephraseToolbar {...rephraseInlineToolbarProps} />}
        <AttachmentDropzone data-testid='richtext-attachment-dropzone' dropzoneDisabled={!isAttachmentsEnabled && !isExpanded} onAttachmentDrop={onAttachmentDrop}>
          {isExpanded && !disabled && (
            <div className="editor-header">
              {renderHeader()}
              {hasFullSize && (
                <button data-testid="change-full-size" className="action-icon-btn exceeded" onClick={toggleFullSize}>
                  {isFullSize ? <IconMinimize /> : <IconMaximize />}
                </button>
              )}
            </div>
          )}
          <FroalaEditor
            ref={editorRef}
            model={editorText}
            onModelChange={handleChange}
            config={{
              key: process.env.REACT_APP_FROALA_API_KEY,
              placeholderText: placeholder,
              ...DEFAULT_CONFIG,
              scrollableContainer: `#scrollable-container-${editorRef?.current?.id}`,
              charCounterMax,
              toolbarButtons: [...DEFAULT_CONFIG.toolbarButtons, ...toolbarButtons],
              toolbarButtonsXS: [...DEFAULT_CONFIG.toolbarButtonsXS, ...toolbarButtons],
              events: {
                initialized() {
                  onInit(this);
                  handleCollapsible();
                },
                keypress() {
                  handleKeypress(this);
                },
                keydown() {
                  handleKeydown(this);
                },
                keyup() {
                  handleKeyup(this);
                },
              },
            }}
          />
        </AttachmentDropzone>

        <UploadFromExistingAttachments
          open={isUploadFormAttachmentsMenuOpened}
          setIsOpen={setIsUploadFormAttachmentsMenuOpened}
        />
        <RichTextDropdown
          editorRef={openDropdown ? editorRef : null}
          pluginKey="cannedResponses"
          toggleDropdown={toggleDropdown}
        />
      </div>
      {isExpanded && CustomFooter}
    </>
  );
};

export default forwardRef(RichTextEditor);
