import moment from 'moment-timezone';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import useDelayHover from 'common/utils/hooks/useDelayHover';
import { selectForceShowValidationError } from 'store/fieldValidationsSlice';
import FieldName from 'features/srPanel/templateFieldsGrid/fieldName';
import { getIsExistingSr } from 'common/utils/srUtils';
import { getFillBarWidth, getLeftTimeIndicator } from 'common/utils/dateTimeUtils';
import { timerService } from 'services/timerService';
import { useFieldAttributes } from 'remote-state/ticketServiceHooks';
import { useGetListValueById } from 'common/utils/hooks/useGetListValueById';
import { useFormatMilliseconds } from 'common/utils/hooks/useFormatMilliseconds';
import DatePickerPopup from '../datePickerPopup';
import DueDateProgressBar from '../dueDateProgressBar';
import { StyledDueDateField, StyledDueDateLabel } from './style';
import useDateTimeInfo from '../../utils/hooks/useDateTimeInfo';
import EmptyDueDate from './EmptyDueDate';
import Tooltip from '../tooltip';
import { STATUS_FIELD_ID } from '../../../features/queue/grid/constants';

//TODO: Refactor component required add useCallback to functions and extract reusable functions to utils, in that way enhance structure and follow DRY concepts.
function DueDateField(props) {
  const {
    url,
    srId,
    field,
    onClick,
    endDate,
    required,
    disabled,
    startDate,
    delay = 0,
    urlParams,
    fieldName,
    onSaveDate,
    displayName,
    hoverEffect,
    handleDirty,
    delayedHover,
    disablePortal,
    srStatusValue,
    hasMoreDetails,
    isTemplatePage,
    updateFieldValidation,
    emptyValueTooltip,
    templateNotFoundMessage,
    notInTemplate,
  } = props;
  const [isDatePopupOpen, setIsDatePopupOpen] = useState(false);
  const [endTime, setEndTime] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [currentTime, setCurrentTime] = useState(new Date().getTime());
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const hoverRef = useRef();
  const dueDateRef = useRef();
  const isDatePopupOpenTimeout = useRef();
  useDelayHover(hoverRef, delayedHover, hoverEffect);

  const { timezone, dateFormat } = useDateTimeInfo();
  const dueDateRemainingTime = useFormatMilliseconds(endTime - currentTime);

  const forceShowError = useSelector(selectForceShowValidationError);
  const fieldAttributesInput = useMemo(() => [{ fieldId: STATUS_FIELD_ID }], []);
  const statusList = useFieldAttributes(fieldAttributesInput)?.[0]?.data?.values;

  const { data } = useGetListValueById({ listKey: STATUS_FIELD_ID, list: statusList, id: srStatusValue });

  const isExistingSR = getIsExistingSr(srId);

  const convertToTimezone = (time) => {
    const selectedDateStr = moment(time)?.format('MM/DD/yyy hh:mm a');
    const selectedMomentStr = moment(selectedDateStr, ['MM/DD/yyy hh:mm a']).format('yyyy-MM-DD HH:mm');
    return moment.tz(selectedMomentStr, timezone).valueOf();
  };

  const checkAndUpdateValidationStatus = useCallback(
    (value) => {
      let isValid = true;
      if (!value && required) {
        isValid = false;
      }
      if (updateFieldValidation) {
        updateFieldValidation(fieldName, required, isValid);
      }
      return isValid;
    },
    [fieldName, required, updateFieldValidation],
  );

  useEffect(() => {
    const isValidDueDate = checkAndUpdateValidationStatus(endDate);
    if (isValidDueDate) {
      setEndTime(endDate);
    } else {
      setEndTime(null);
    }

    if (startDate && startDate > 0) {
      setStartTime(startDate);
    } else {
      setStartTime(new Date().setSeconds(0, 0));
    }
    setIsDataLoaded(true);
  }, [startDate, endDate, checkAndUpdateValidationStatus]);

  useEffect(() => {
    const subscription = timerService.register().subscribe((date) => {
      setCurrentTime(date.setSeconds(0, 0));
    });
    return () => {
      subscription.unsubscribe();
      if (isDatePopupOpenTimeout.current) {
        clearTimeout(isDatePopupOpenTimeout.current);
      }
    };
  }, []);

  const handleOnClick = () => {
    if (!disabled) {
      if (onClick) {
        onClick();
      }
      isDatePopupOpenTimeout.current = setTimeout(() => {
        setIsDatePopupOpen(true);
      }, delay);
    }
  };

  const handleSaveDate = (timestamp) => {
    checkAndUpdateValidationStatus(timestamp);

    if (onSaveDate && endDate !== timestamp) {
      onSaveDate(timestamp);
    }
    setEndTime(timestamp);
  };

  const onClosePopup = () => {
    setIsDatePopupOpen(false);
  };

  const handlePopupDateChange = (date) => {
    if (handleDirty) {
      handleDirty({ [field?.fieldName]: date });
    }
    handleSaveDate(date);
    onClosePopup();
  };

  const clearValueHandler = () => {
    handleSaveDate(0);
    onClosePopup();
  };

  const setDefaultEndTime = () => {
    const currentTime = new Date();
    const todayMidnight = currentTime.setHours(0, 0, 0, 0);
    let newEndTime = todayMidnight + 18 * 60 * 60 * 1000;
    if (currentTime.getHours() >= 18) {
      newEndTime = newEndTime.setDate(newEndTime.getDate() + 1);
    }
    return convertToTimezone(newEndTime);
  };

  const CLOSED_CLASS = 1;
  let isSrInClosedClass = false;
  if (statusList && srStatusValue) {
    const statusElement = statusList.find((el) => el.id === srStatusValue);
    if (statusElement && statusElement.valueClass) {
      const statusClass = statusElement.valueClass;
      if (statusClass === CLOSED_CLASS) {
        isSrInClosedClass = true;
      }
    }
  }

  const fillBarWidth = getFillBarWidth(startTime, currentTime, endTime);
  const leftTimeIndicator = getLeftTimeIndicator(data, startTime, currentTime, endTime);
  const bgColorIndicator = isSrInClosedClass ? 'closed' : leftTimeIndicator;
  const isNewField = !isExistingSR;

  const isError = useMemo(() => {
    const newFieldError = isNewField && required && forceShowError;
    const existingFieldError = !isNewField && required;
    return newFieldError || existingFieldError;
  }, [forceShowError, isNewField, required]);

  return (
    <div ref={hoverRef}>
      {displayName && (
        <FieldName
          hasValue={endTime}
          required={required}
          hasMoreDetails={hasMoreDetails}
          url={url}
          urlParams={urlParams}
          fieldId={field?.fieldId}
          isTemplatePage={isTemplatePage}
          name={displayName}
          hintText={field?.hint && field?.hintText}
        />
      )}
      {isDataLoaded && (
        <div>
          {!endTime || notInTemplate ? (
            <Tooltip
              isError={!notInTemplate && isError}
              title={notInTemplate ? templateNotFoundMessage : emptyValueTooltip}
              placement="top"
              disableHoverListener={!notInTemplate && (!required || !emptyValueTooltip || !isError)}
              text={
                <EmptyDueDate
                  onClick={handleOnClick}
                  dueDateRef={dueDateRef}
                  isError={isError}
                  isNewField={isNewField}
                  isTemplatePage={isTemplatePage}
                  disabled={disabled}
                />
              }
            />
          ) : (
            <StyledDueDateField
              ref={dueDateRef}
              bgColorIndicator={bgColorIndicator}
              onClick={handleOnClick}
              disabled={disabled}
              className="dueDateField"
            >
              {data?.valueClass === 1 ? (
                <>
                  <StyledDueDateLabel>
                    <Tooltip
                      arrow
                      placement="top"
                      text={moment.tz(endTime, timezone).format('L')}
                      title={moment.tz(endTime, timezone).format(dateFormat)}
                    />
                  </StyledDueDateLabel>
                  <DueDateProgressBar bgcolorindicator={bgColorIndicator} />
                </>
              ) : (
                <>
                  <StyledDueDateLabel>
                    <Tooltip
                      arrow
                      placement="top"
                      text={dueDateRemainingTime}
                      title={moment.tz(endTime, timezone).format(dateFormat)}
                    />
                  </StyledDueDateLabel>
                  <DueDateProgressBar value={fillBarWidth} bgcolorindicator={bgColorIndicator} disabled={disabled} />
                </>
              )}
            </StyledDueDateField>
          )}
        </div>
      )}
      {isDatePopupOpen && !disabled && (
        <DatePickerPopup
          date={endTime}
          open={isDatePopupOpen}
          disablePortal={disablePortal}
          defaultDate={setDefaultEndTime()}
          onClosePopup={handlePopupDateChange}
          clearValueHandler={clearValueHandler}
          coord={dueDateRef.current?.getBoundingClientRect()}
        />
      )}
    </div>
  );
}

export default memo(DueDateField);
