import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  selectForceFloatingTicketShowValidationError,
  selectForceShowValidationError,
} from 'store/fieldValidationsSlice';
import { ReactComponent as WarningIcon } from 'images/icons/warning_icon.svg';
import { actionEnums } from 'features/resolutionPanel/middlePanel/auditLog/constants';
import { editorActionTypes } from 'features/resolutionPanel/middlePanel/auditLog/constants/editorActionTypes';
import TruncatedTextButton from 'features/resolutionPanel/middlePanel/auditLog/logDescription/editor/truncatedTextButton';
import { useDisplayTextTruncation } from 'features/resolutionPanel/middlePanel/auditLog/logDescription/fieldChange/hooks/useDisplayTextTruncation';
import { RICH_TEXT_EDITOR_TYPES } from 'common/components/controls/RichTextEditor/constants';
import RichTextEditor from 'common/components/controls/RichTextEditor';
import Tooltip from 'common/components/tooltip';
import { useRichTextEditor } from './useRichTextEditor';
import { useSimpeContentValidation } from './useSimpleContentValidation';
import FieldName from '../fieldName';
import useTexts from '../useTexts';
import { StyledRichTextFieldWrapper } from './style';

const RichTextField = memo(
  ({
    field,
    sourceId,
    displayName,
    fieldValue,
    required,
    onFocus,
    handleSaveValue,
    handleDirty,
    isTemplatePage,
    fieldRef,
    hasMoreDetails,
    url,
    urlParams,
    updateFieldValidation,
    isReadOnly,
    isNewField = false,
  }) => {
    const { descriptionPlaceholder, mandatoryFieldError } = useTexts();
    const forceShowError = useSelector( isNewField ? selectForceFloatingTicketShowValidationError : selectForceShowValidationError);
    const richTextWrapperRef = useRef(null);
    const { showTruncationButton } = useDisplayTextTruncation({
      divRef: richTextWrapperRef,
      logType: actionEnums.FIELD_REGULAR,
      maxRowsAmount: 7,
      isRichText: true,
    });
    const {
      data,
      isEditorDirty,
      activeUploads,
      handleSave,
      handleChange,
      handleFileAttach,
      handleImageUpload,
      handleChangeActiveUploads,
      handleRemoveAttachmentById,
    } = useRichTextEditor({
      sourceId,
      required,
      fieldValue,
      fieldName: field?.fieldName,
      fieldId: field?.fieldId,
      handleSaveValue,
      handleDirty,
      shouldAddField: true,
      isTemplatePage,
      updateFieldValidation,
    });

    const [isFullSize, setIsFullSize] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isRichTextTruncated, setIsRichTextTruncated] = useState(false);

    const isSimpleContent = useSimpeContentValidation(data.text);

    const toggleRichTextTruncate = () => {
      setIsRichTextTruncated((prev) => !prev);
    };
    const toggleFullSize = () => {
      setIsFullSize(!isFullSize);
    };

    const handleBlur = () => {
      const container = richTextWrapperRef?.current.querySelector('.fr-view');
      container?.scrollTo(0, 0);
      handleSave();
      if (!isTemplatePage) {
        setIsExpanded(false);
      }
    };

    useEffect(() => {
      const uploads = activeUploads.length + data?.attachments?.length;
      if (isTemplatePage && richTextWrapperRef?.current && fieldRef?.current && uploads > 0) {
        const attachmentsBlockHeight =
          richTextWrapperRef.current.querySelector('.attachments-wrapper')?.clientHeight || 0;
        fieldRef.current.style.marginBottom = isExpanded ? `${attachmentsBlockHeight + 12}px` : '';
      }
    }, [isTemplatePage, fieldRef, richTextWrapperRef, activeUploads.length, data?.attachments?.length, isExpanded]);

    const isEmptyRequiredField = useMemo(() => {
      const isInvalid = !data?.text && required;
      const newFieldError = isNewField && isInvalid && forceShowError;
      const existingFieldError = !isNewField && isInvalid;
      return newFieldError || existingFieldError;
    }, [data?.text, forceShowError, isNewField, required]);

    return (
      <>
        <FieldName
          name={displayName}
          fieldId={field?.fieldId}
          required={required}
          hasMoreDetails={hasMoreDetails}
          url={url}
          urlParams={urlParams}
          isTemplatePage={isTemplatePage}
          hintText={field?.hint && field?.hintText}
        />
        <StyledRichTextFieldWrapper
          isTemplatePage={isTemplatePage}
          isRichTextTruncated={isRichTextTruncated}
          isExpanded={isExpanded}
          isError={isEmptyRequiredField}
          ref={richTextWrapperRef}
          isSimpleContent={isSimpleContent}
        >
          <RichTextEditor
            fieldName={displayName}
            sourceId={sourceId}
            isReadOnly={isReadOnly}
            editorText={data?.text}
            attachmentsList={data?.attachments}
            placeholder={descriptionPlaceholder}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={onFocus}
            isExpanded={isExpanded}
            handleExpanded={setIsExpanded}
            isFullSize={isFullSize}
            isEditorDirty={isEditorDirty}
            isRichTextTruncated={isRichTextTruncated}
            toggleFullSize={toggleFullSize}
            onFileAttach={handleFileAttach}
            onImageUpload={handleImageUpload}
            activeUploads={activeUploads}
            onChangeActiveUploads={handleChangeActiveUploads}
            onAttachmentDeleted={handleRemoveAttachmentById}
            shouldTriggerDeleteApi={false}
            editorType={editorActionTypes.RICH_TEXT_FIELD}
            type={isTemplatePage ? RICH_TEXT_EDITOR_TYPES.TEMPLATE : RICH_TEXT_EDITOR_TYPES.TEXT}
            customDefaultUI={
              data?.text && !isExpanded && showTruncationButton ? (
                <TruncatedTextButton isRichTextTruncated={isRichTextTruncated} onClick={toggleRichTextTruncate} />
              ) : null
            }
          />
          {isEmptyRequiredField && (
            <div className="warning-icon-wrapper">
              <Tooltip
                isError
                title={mandatoryFieldError}
                placement="top"
                text={<WarningIcon className="warning-icon" />}
              />
            </div>
          )}
        </StyledRichTextFieldWrapper>
      </>
    );
  },
);

export default RichTextField;
