import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();

  const componentTexts = {
    searchTitle: texts?.['spaces.menu.settings.search'],
    headerTitle: texts?.['spaces.menu.settings'],
    availableUpgrade: texts?.['spaces.menu.settings.availableUpgrade'] || 'Available Upgrade',
    ...texts,
  };

  return componentTexts;
}
