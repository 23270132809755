import { useCallback, useMemo } from 'react';
import { useApplicationData } from 'remote-state/applicationHooks';
import { useUserInfo } from 'remote-state/userServiceHooks';
import { QUERIES_KEYS } from 'constant';
import ForwardRefFilter from 'common/components/forwardRefFilter';
import { getIsCategory, getIsUserFieldByFieldName } from 'common/utils/fieldUtils';
import { FILTER_TYPE_CONFIGURATIONS, RESPONSIBLE_MANAGER_FIELD_ID, COLUMN_CELL_TYPES } from '../constants';
import ActionBtnRenderer from '../actionBtnRenderer';
import DefaultCellRender from '../defaultCellRender';
import CustomSelectBox from '../customSelectBox';
import theme from '../../../theme/themes/lightTheme';
import { useFeatureFlagQuery } from '../../../../remote-state/featureFlagsHooks';
import { FEATURE_FLAGS_KEYS } from '../../../../constants/featureFlags';

const requiredFields = ['status', 'assignee', 'srType'];

export const useGetColumnDefinitions = (sr, getColumnText) => {
  const { data: isTripleCategory } = useApplicationData(QUERIES_KEYS.IS_EXTERNAL_MODE);
  const { data: currentUserPermissions } = useUserInfo(QUERIES_KEYS.CURRENT_USER_PERMISSIONS);
  const { data: isSROpenedFromQueue } = useFeatureFlagQuery({
    flagKey: FEATURE_FLAGS_KEYS.OPEN_SR_FROM_QUEUE,
    defaultValue: false,
  });

  const isBulkActionsEnabled = currentUserPermissions?.userPermissionEnableBulkActionsFromList;
  const staticColumns = useMemo(() => {
    const defaultConfig = {
      headerName: '',
      hide: false
    }

    if (isBulkActionsEnabled) {
      return [
        {
          field: 'delete',
          lockPosition: true,
          pinned: 'left',
          resizable: false,
          width: 20,
          headerClass: 'deleteClassName',
          cellRenderer: ActionBtnRenderer,
          ...defaultConfig
        },
        {
          field: 'checkbox',
          checkboxSelection: true,
          lockPosition: true,
          pinned: 'left',
          lockPinned: true,
          resizable: false,
          width: 32,
          minWidth: 32,
          suppressSizeToFit: true,
          headerClass: 'checkboxClassName',
          cellRenderer: CustomSelectBox,
          ...defaultConfig
        },
        ...(isSROpenedFromQueue
          ? []
          : [
              {
                fieldTypeName: 'OPEN_LINK',
                field: 'openticket',
                lockPosition: true,
                pinned: 'left',
                lockPinned: true,
                resizable: false,
                width: 70,
                minWidth: 32,
                suppressSizeToFit: true,
                headerClass: 'opensas',
                cellRenderer: DefaultCellRender,
                ...defaultConfig
              },
            ]),
      ];
    }
    return [
      {
        field: 'delete',
        lockPosition: true,
        pinned: 'left',
        resizable: false,
        width: 20,
        headerClass: 'deleteClassName',
        cellRenderer: ActionBtnRenderer,
        ...defaultConfig
      },
      ...(isSROpenedFromQueue
        ? []
        : [
            {
              fieldTypeName: 'OPEN_LINK',
              field: 'openticket',
              lockPosition: true,
              pinned: 'left',
              lockPinned: true,
              resizable: false,
              width: 70,
              minWidth: 32,
              suppressSizeToFit: true,
              headerClass: 'opensas',
              cellRenderer: DefaultCellRender,
              ...defaultConfig
            },
          ]),
    ];
  }, [isBulkActionsEnabled, isSROpenedFromQueue]);

  const getColumnConfigByType = useCallback(
    ({ fieldType, fieldId, fieldName, isLast = false }) => {
      const selectFieldParams = {
        sortBy: getIsUserFieldByFieldName(fieldName) ? 'valueCaption' : undefined,
        keyString: fieldId === RESPONSIBLE_MANAGER_FIELD_ID ? 'value' : 'valueKey', // fieldId === CC_USER_FIELD_ID @TODO return this when the bug on the BE regarding CC filter is fixed
        captionString: 'valueCaption',
      };
      const defaultConfig = {
        minWidth: 90,
        width: 150,
        maxWidth: isLast ? undefined : 500,
        hide: false,
        ...(isLast ? { flex: 1 } : {})
      };

      const params = (() => {
        switch (fieldType) {
          case COLUMN_CELL_TYPES.TEXT:
            return {
              ...defaultConfig,
              minWidth: 150,
              width: 240,
              maxWidth: undefined,
            };
          case COLUMN_CELL_TYPES.LONGTEXT:
            return {
              ...defaultConfig,
              minWidth: 150,
              width: 240,
              maxWidth: undefined,
              flex: undefined
            };
          case COLUMN_CELL_TYPES.NUMBER:
            if (fieldName === 'id') {
              return {
                ...defaultConfig,
                pinned: 'left',
                lockPosition: true,
                lockPinned: true,
                width: 90,
                resizable: false,
                suppressSizeToFit: true,
                ...(isSROpenedFromQueue && {
                  cellStyle: {
                    borderRight: `1px solid ${theme.palette.grey.divider_grey_v1}`,
                    marginLeft: '-12px',
                  },
                }),
              };
            }
            return {
              ...defaultConfig,
              maxWidth: isLast ? undefined : 150,
            };
          case COLUMN_CELL_TYPES.MULTISELECT:
            return {
              ...defaultConfig,
              ...selectFieldParams,
              width: 250,
            };
          case COLUMN_CELL_TYPES.SELECT:
            if (getIsCategory(fieldId)) {
              return {
                ...defaultConfig,
                minWidth: 140,
                captionString: 'valueCaption',
                width: 140,
              };
            }
            if (fieldName === 'assignee' || fieldName === 'assignedGroup') {
              return {
                ...defaultConfig,
                width: 200,
              };
            }
            if (fieldName === 'requestUser') {
              return {
                ...defaultConfig,
                ...selectFieldParams,
                width: 200,
              };
            }
            if (fieldName === 'requestUserManager') {
              return { ...defaultConfig, width: 250 };
            }
            if (['srType', 'status', 'priority', 'urgency', 'impact'].includes(fieldName)) {
              return {
                ...defaultConfig,
                ...selectFieldParams,
                width: 120,
              };
            }
            return {
              ...defaultConfig,
              ...selectFieldParams,
            };
          case COLUMN_CELL_TYPES.DATE:
          case COLUMN_CELL_TYPES.TIME_REMAINING:
          case COLUMN_CELL_TYPES.DATEANDTIME:
            return {
              ...defaultConfig,
              width: fieldName === 'dueDate' ? 140 : 200,
            };
          default:
            return defaultConfig;
        }
      })();
      return params;
    },
    [isSROpenedFromQueue],
  );

  const columns = useMemo(() => {
    if (sr) {
      let columnsArr;
      //Check if third level category turned off in general settings if so the remove it from list.
      if (!isTripleCategory) {
        columnsArr = sr.filter((col) => col.fieldName !== 'thirdLevelCategory');
      } else {
        columnsArr = sr;
      }
      return columnsArr.map(
        ({ fieldName: name, id, ticketTemplateFieldType, sysaidEntity, displayName, customColumn }, index) => {
          const headerFromRB = getColumnText && getColumnText(displayName);
          const headerName = headerFromRB || displayName;
          const isLast = index === columnsArr.length - 1

          const column = {
            field: name,
            fieldId: id,
            sysaidEntity,
            fieldTypeId: ticketTemplateFieldType?.id,
            fieldTypeName: ticketTemplateFieldType?.typeName?.toUpperCase(),
            sortable: true,
            suppressMenu: true,
            headerClass: `${name}HeaderClass ${index >= 1 ? 'unlockedColumn' : ''}`,
            headerName,
            filter: ForwardRefFilter,
            filterParams: {
              filterConf: FILTER_TYPE_CONFIGURATIONS[ticketTemplateFieldType?.id],
              listKey: name,
            },
            customColumn,
            cellRenderer: DefaultCellRender,
            ...getColumnConfigByType({
              fieldType: ticketTemplateFieldType?.typeName?.toUpperCase(),
              fieldName: name,
              fieldId: id,
              headerName,
              isLast
            }),
          };
          return column;
        },
      );
    }
    return [];
  }, [sr, getColumnText, getColumnConfigByType, isTripleCategory]);

  const hiddenColumns = useMemo(() => {
    const hiddenColumnsList = [];
    const existingNamesSet = new Set(columns.map((obj) => obj.field));
    requiredFields.forEach((field) => {
      if (!existingNamesSet.has(field)) {
        hiddenColumnsList.push({
          field,
          filter: ForwardRefFilter,
          hide: true,
        });
      }
    });
    return hiddenColumnsList;
  }, [columns]);

  const arrayToReturn = useMemo(
    () => [...staticColumns, ...columns, ...hiddenColumns],
    [staticColumns, columns, hiddenColumns],
  );

  return arrayToReturn;
};
