import { Box, MenuItem, Popper } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledPopper = styled(Popper, {
  shouldForwardProp: (prop) => prop !== 'width',
})(({ theme, width }) => ({
  zIndex: '999999',
  '& .MuiPaper-root': {
    minWidth: `${width}px !important`,
    width: `${width}px`,
    boxShadow: '0px 2px 8px rgba(118, 130, 150, 0.35)',
    marginTop: '0',
  },
  '& .MuiMenuItem-root': {
    cursor: 'pointer',
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    color: theme.palette.text.title_txt,
    fontFamily: theme.fonts.robotoRegular,
    width: '90% !important',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: theme.palette.grey.bt_alt_grey_light,
    },
    '&:disabled': {
      color: theme.palette.grey.bt_def_grey_light,
    },
  },
  '&& .Mui-selected': {
    backgroundColor: theme.palette.grey.bt_alt_grey_light,
    '&:hover': {
      backgroundColor: theme.palette.grey.bt_alt_grey_light,
    },
  },
}));

export const StyledHeaderProps = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '12px 16px 8px 16px',
  gap: '8px',
}));

export const StyledNoResults = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '4px 12px 12px',
  fontSize: '0.875rem',
  color: theme.palette.text.title_txt,
  fontFamily: theme.fonts.robotoRegular,
}));

export const StyledMenuItem = styled(MenuItem)(() => ({
  paddingRight: '8px',
  '.menu-item-text': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));
